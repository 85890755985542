import { Observable } from 'rxjs';
import Api from '../../../../core/services/Api';
import { WalletChargeResponseModel, WalletModel } from '../model/wallet.model';
import { WALLET_BALANCE_API_URL, WALLET_CHARGE_API_URL } from '../CONSTANTS';
import { getApiHeaderObjectWithToken } from '../../../../core/services/config';
import { getAppPackage, getLoginAccessToken } from '../../../../core/services/localStorage.service';
import i18n from 'i18next';

export class WalletService {
  public getWalletAmount(): Observable<WalletModel> {
    return Api.getWithHeader<WalletModel>(
      WALLET_BALANCE_API_URL,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }

  public chargeWalletAmount(amount: string): Observable<WalletChargeResponseModel | void> {
    return Api.postWithHeader<WalletChargeResponseModel>(
      WALLET_CHARGE_API_URL,
      {
        amount: amount
      },
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }
}
