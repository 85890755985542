export const EXCHANGE_RATE_PATH = 'p/tools/v1/exchange_rate/';
export const EXCHANGE_RATE_WIDGET_PATH = 'p/tools/v1/exchange_rate/home';

export const CURRENCY_TYPE = 'currency';
export const GOLD_TYPE = 'gold';
export const COIN_TYPE = 'coin';

export const TOP_ARROW_IMAGE_PATH = '/image/arrow_up.svg';
export const DOWN_ARROW_IMAGE_PATH = '/image/arrow_down.svg';

export const COIN_IMAGE_PATH = '/image/coin.webp';
export const GOLD_IMAGE_PATH = '/image/gold.webp';
export const CURRENCY_IMAGE_PATH = '/image/currency.webp';
