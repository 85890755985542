import React, {FC, useEffect, useState} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode} from 'swiper';
import {DiscountService} from '../../../../club/Discount/service/discountService';
import {DiscountResults} from '../../../../club/Discount/model/discount.model';
import {DiscountItemComponent} from '../../../../club/Discount/component/discountItem.component';
import {useRouter} from 'next/router';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

export const DiscountWidgetComponent: FC<WidgetProps> = (props) => {
  const discountService = new DiscountService();
  const [discountList, setDiscountList] = useState<DiscountResults[]>([]);
  const [emptyDiscount, setEmptyDiscount] = useState(false);
  const router = useRouter();

  useEffect(() => {
    discountService.getDiscounts().subscribe((data) => {
      if (data.data && data.data.results && data.data.results.length > 0) {
        setDiscountList(data.data.results);
      }else{
        setEmptyDiscount(true);
      }
    });
  }, []);

  function onDiscountItemClick(id: number) {
    router.push(`/tools/club/discounts?discount_id=${id}`);
  }

  return (
    <WidgetContainerComponent className={`totalCenter ${emptyDiscount?"hidden":""}`}>
      <ColumnComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      </ColumnComponent>
      <RowComponent className={'flex-nowrap'}>
        {discountList.length > 0 ? (
          <Swiper
            slidesPerView={'auto'}
            freeMode={true}
            modules={[FreeMode]}
            style={{ width: '100%', paddingRight: '.6rem', paddingLeft: '.6rem' }}
            className="mySwiper"
          >
            {discountList.map((discount, index) => (
              <SwiperSlide
                className={'!w-10/12  md:!w-1/3 lg:!w-1/4'}
                style={{ paddingLeft: '.2rem', paddingRight: '.4rem', paddingBottom: '.6rem' }}
                key={index}
                virtualIndex={index}
              >
                <DiscountItemComponent
                  itemClick={() => onDiscountItemClick(discount.id)}
                  discount={discount}
                  key={index}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </RowComponent>
    </WidgetContainerComponent>
  );
};
