import React, {FC, useEffect} from 'react';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {useTranslation} from 'react-i18next';
import {Stack} from '@mui/material';
import {ExchangeRateService} from '../../../../tools/exchange_rate/service/exchangeRate.service';
import {useRouter} from 'next/router';
import {EXCHANGE_RATE} from '../../../../../core/navigation/CONSTANTS';
import {toPersianNumber} from '../../../../../core/utils/number.utils';
import {COIN_IMAGE_PATH, CURRENCY_IMAGE_PATH, GOLD_IMAGE_PATH} from '../../../../tools/exchange_rate/CONSTANTS';
import Image from 'next/image';

export const ExchangeRateWidgetComponent: FC<WidgetProps> = (props) => {
  const { t } = useTranslation();
  const exchangeService = new ExchangeRateService();
  const router = useRouter();
  const [goldValue, setGoldValue] = React.useState('');
  const [coinValue, setCoinValue] = React.useState('');
  const [currencyValue, setCurrencyValue] = React.useState('');

  useEffect(() => {
    exchangeService.getExchangeRate().subscribe((data) => {
      if (data && data.data) {
        const gold = data.data.find((item) => {
          return item.slug === 'TALA_18';
        });
        const coin = data.data.find((item) => {
          return item.slug === 'SEKE_BAHAR';
        });
        const currency = data.data.find((item) => {
          return item.slug === 'USD';
        });
        setGoldValue(Number(gold?.price ?? '0')?.toLocaleString());
        setCoinValue(Number(coin?.price ?? '0')?.toLocaleString());
        setCurrencyValue(Number(currency?.price ?? '0')?.toLocaleString());
      }
    });
  }, []);

  function onClickAll() {
    router.push({ pathname: EXCHANGE_RATE, search: `shTitle=${t('shName.exchange_rate')}` });
  }

  function itemClick(position: number) {
    router.push({ pathname: EXCHANGE_RATE, search: `id=${position}&shTitle=${t('shName.exchange_rate')}` });
  }

  return (
    <ColumnComponent>
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <Stack spacing={1} className={'mt-4 pb-6 px-4 !w-full md:!w-1/2 lg:!w-1/3'} direction={'row'}>
        <div className={'rounded-lg card-1 p-2 flex-col flex w-full totalCenter pointer'} onClick={() => itemClick(0)}>
          <RowComponent className={'totalCenter'}>
            <Image fill={true} sizes={'100%'} alt={''} className={'!w-10 !h-10 !relative'} src={CURRENCY_IMAGE_PATH} />
            <div className={'px-2 text-sm'}>{t('financial.dollar')}</div>
          </RowComponent>
          <div className={'text-xs mt-2'}>
            {toPersianNumber(currencyValue)} {t('financial.rial')}
          </div>
        </div>
        <div className={'rounded-lg card-1 p-2 flex-col flex w-full totalCenter pointer'} onClick={() => itemClick(1)}>
          <RowComponent className={'totalCenter'}>
            <Image fill={true} sizes={'100%'} alt={''} className={'!w-10 !h-10 !relative'} src={COIN_IMAGE_PATH} />
            <div className={'px-2 text-sm'}>{t('financial.coin')}</div>
          </RowComponent>
          <div className={'text-xs mt-2'}>
            {toPersianNumber(coinValue)} {t('financial.rial')}
          </div>
        </div>
        <div className={'rounded-lg card-1 p-2 flex-col flex w-full totalCenter pointer'} onClick={() => itemClick(2)}>
          <RowComponent className={'totalCenter'}>
            <Image fill={true} sizes={'100%'} alt={''} className={'!w-10 !h-10 !relative'} src={GOLD_IMAGE_PATH} />
            <div className={'px-2 text-sm'}>{t('financial.gold')}</div>
          </RowComponent>
          <div className={'text-xs mt-2'}>
            {toPersianNumber(goldValue)} {t('financial.rial')}
          </div>
        </div>
      </Stack>
    </ColumnComponent>
  );
};
