import React, {FC} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {FreeMode} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import RectangularShortcutComponent from '../shortcut/rectangularShortcut.component';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

export const HorizontalRectangularSwiperComponent: FC<WidgetProps> = (props) => {
  return (
    <div className="fullWidth">
      <WidgetContainerComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
        <RowComponent
          className={`${props.widget.schema_data?.background_color ? 'py-2' : ''} relative totalCenter`}
          style={{ backgroundColor: props.widget.schema_data?.background_color ?? 'transparent' }}
        >
          {props.widget.schema_data?.side_image && props.widget.schema_data?.side_image_behavior === 'fading' ? (
            <img
              alt={''}
              src={props.widget.schema_data.side_image}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'fill' }}
              className={'swiperRecyclerFirstImage absolute right-0'}
            />
          ) : null}
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar px-2">
            <Swiper
              slidesPerView={'auto'}
              freeMode={true}
              modules={[FreeMode]}
              style={{
                paddingRight:
                  props.widget.schema_data.side_image && props.widget.schema_data?.side_image_behavior === 'fading'
                    ? '10rem'
                    : ''
              }}
              className="mySwiper"
            >
              {props.widget.schema_data.side_image && props.widget.schema_data?.side_image_behavior === 'scrolling' ? (
                <SwiperSlide style={{ width: 'auto' }} key={0} virtualIndex={0}>
                  <img
                    alt={''}
                    src={props.widget.schema_data.side_image ?? ''}
                    width={'100%'}
                    height={'100%'}
                    style={{ objectFit: 'fill' }}
                    className={`swiperRecyclerFirstImage `}
                  />
                </SwiperSlide>
              ) : null}
              {props.widget.shortcuts?.map((item, index) => (
                <SwiperSlide style={{ width: 'auto' }} key={index + 1} virtualIndex={index + 1}>
                  {index < (props.widget.schema_data.unit_max_size ?? 100) * 4 ? (
                    <div key={index} className="p-2 width-auto">
                      <RectangularShortcutComponent
                        shortcut={item}
                        textColor={props.widget.schema_data?.text_color ?? null}
                      />
                    </div>
                  ) : null}
                </SwiperSlide>
              ))}
            </Swiper>
          </RowComponent>
        </RowComponent>
      </WidgetContainerComponent>
    </div>
  );
};

export default React.memo(HorizontalRectangularSwiperComponent);
