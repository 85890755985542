import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {VideoService} from '../../../../tools/video/service/video.service';
import {VideoListModel} from '../../../../tools/video/model/video.model';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {useRouter} from 'next/router';
import {FreeMode} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Skeleton} from '@mui/material';
import ShortVideoShortcutComponent from '../shortcut/shortVideoShortcut.component';
import {SHORT_VIDEO_PATH} from '../../../../../core/navigation/CONSTANTS';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

const ShortVideoWidgetComponent: FC<WidgetProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const videoService = new VideoService();
  let [videoList, setVideoList] = useState<VideoListModel[]>([]);

  useEffect(() => {
    if (props.widget.schema_data.source_url) {
      videoService.getVideoList(props.widget.schema_data.source_url).subscribe((data) => {
        if (data && data.results.length > 0) {
          setVideoList(data.results);
        }
      });
    }
  }, []);

  function onClickAll() {
    router.push({
      pathname: SHORT_VIDEO_PATH,
      search: `position=${0}&url=${encodeURIComponent(props.widget.schema_data.source_url ?? '')}&shTitle=${
        props.widget.title
      }`
    });
  }

  return (
    <WidgetContainerComponent className="fullWidth">
      <div>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
        {videoList?.length > 0 ? (
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar px-2">
            <Swiper slidesPerView={'auto'} freeMode={true} modules={[FreeMode]} className="mySwiper">
              {videoList?.map((item, index) => (
                <SwiperSlide style={{ width: 'auto' }} key={index} virtualIndex={index}>
                  <div className="w-auto p-1" key={index}>
                    <ShortVideoShortcutComponent
                      index={index}
                      video={item}
                      widgetTitle={props.widget.title}
                      sourceUrl={props.widget.schema_data.source_url}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </RowComponent>
        ) : (
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar">
            {[...Array(5)].map((i, index) => (
              <div key={index} className="w-auto p-2">
                <Skeleton className="card card-box rounded-1 shortVideoIconImage" variant="rectangular" />
              </div>
            ))}
          </RowComponent>
        )}
      </div>
    </WidgetContainerComponent>
  );
};

export default React.memo(ShortVideoWidgetComponent);
