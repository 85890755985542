import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {WidgetProps} from './component/widgetTitleBar';
import {useRouter} from 'next/router';
import {WalletService} from '../../../../tools/wallet/service/wallet.service';
import {WALLET_CARD_IMAGE_PATH, WALLET_SIM_IMAGE_PATH} from '../../../../tools/wallet/CONSTANTS';
import {TextComponent} from '../../../../../core/components/base/textField.component';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import {LOGIN_STATE_KEY} from '../../../../../core/services/CONSTANTS';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {toPersianNumber} from '../../../../../core/utils/number.utils';
import {Skeleton} from '@mui/material';
import {blue} from '@mui/material/colors';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {WALLET} from '../../../../../core/navigation/CONSTANTS';
import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const WalletWidgetComponent: FC<WidgetProps> = (props) => {
    const {t} = useTranslation();
    const router = useRouter();
    let dispatch = useDispatch<AppDispatch>();
    const walletService = new WalletService();
    const [amountValue, setAmountValue] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

    useEffect(() => {
        if (isLogin) {
            walletService.getWalletAmount().subscribe((data) => {
                if (data && data.data) {
                    setLoading(false);
                    setAmountValue(data.data[0].balance);
                }
            });
        }
    }, [isLogin]);

    function onClickTransactions(chargeWallet: boolean) {
        if (isLogin) {
            router.push({
                pathname: WALLET,
                search: `shTitle=${t('financial.wallet')}${chargeWallet ? '&chargeWallet=1' : ''}`
            });
        }
    }

    function loginClick() {
        dispatch({
            type: LOGIN_ACTION,
            openLoginPage: true,
            state: LOGIN_STATE
        });
    }

    return (
        <div className="flex flex-col relative !w-full md:!w-2/3 lg:!w-1/2 overflow-hidden rounded-md totalCenter">
            <img
                alt={''}
                src={WALLET_CARD_IMAGE_PATH}
                className={'absolute left-0 right-0 top-0 object-cover !h-full'}
                width={'100%'}
                height={'100%'}
            />
            <img alt={''} src={WALLET_SIM_IMAGE_PATH} className={'!h-10 !w-10 absolute left-4 top-4'}/>
            {isLogin ? (
                <RowComponent
                    className={'flex flex-row mt-6 mb-6 text-white px-4 z-20 pointer'}
                    onClick={() => onClickTransactions(false)}
                >
                    {loading ? (
                        <Skeleton
                            sx={{bgcolor: blue[800]}}
                            className="rounded-md"
                            height="1.5rem"
                            width={'3rem'}
                            variant={'rectangular'}
                        />
                    ) : (
                        <TextComponent
                            text={toPersianNumber(Number(amountValue ?? '0').toLocaleString())}
                            isFromTranslate={false}
                            size={'lg'}
                            width={'w-auto'}
                            className={'w-auto text-bold h-full'}
                        />
                    )}
                    <TextComponent
                        text={t('financial.rial')}
                        isFromTranslate={false}
                        size={'lg'}
                        className={'w-full mx-2 text-bold h-full'}
                    />
                </RowComponent>
            ) : (
                <TextComponent
                    text={'profile.digitalWallet'}
                    className={' mt-6 mb-6 z-20 py-2 px-4 text-white pointer'}
                    isFromTranslate={true}
                />
            )}
            {isLogin ?
                <div className={'flex flex-row w-full md:w-2/3 z-10 px-2 mb-2 '}>
                    <RowComponent
                        className={'mx-2 rounded-md bg-white px-4 py-2 totalCenter pointer'}
                        onClick={() => onClickTransactions(true)}>
                        <AddOutlinedIcon fontSize={'small'} style={{color: 'var(--theme-color-blue)'}}/>
                        <div className="mTextBlue mx-2">{t('financial.chargeWallet')}</div>
                    </RowComponent>
                    <RowComponent
                        className={'mx-2 rounded-md bg-white px-4 py-2 totalCenter pointer'}
                        onClick={() => onClickTransactions(false)}>
                        <FileCopyOutlinedIcon fontSize={'small'} style={{color: 'var(--theme-color-blue)'}}/>
                        <div className="mTextBlue mx-2">{t('financial.transactions')}</div>
                    </RowComponent>
                </div>
                :
                    <div
                        className={'rounded-md flex flex-row w-2/3 md:w-1/2 mb-3 bg-white px-4 py-2 totalCenter pointer z-10'}
                        onClick={() => loginClick()}>
                        <AccountCircleOutlinedIcon fontSize={'small'} style={{color: 'var(--theme-color-blue)'}}/>
                        <div className="mTextBlue mx-2">{t('profile.loginSignUp')}</div>
                    </div>
            }
        </div>
    );
};

export default React.memo(WalletWidgetComponent);
