import React, {FC, useEffect, useState} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {ColumnComponent} from '../../../../../core/components/base/column.component';

import {LOGIN_STATE_KEY} from '../../../../../core/services/CONSTANTS';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode} from 'swiper';
import {MissionResult} from '../../../../club/mission/model/mission.model';
import {MissionService} from '../../../../club/mission/service/mission.service';
import {MissionItemComponent} from '../../../../club/mission/component/missionItem.component';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';
import {Skeleton} from "@mui/material";

export const MissionWidgetComponent: FC<WidgetProps> = (props) => {
  const missionService = new MissionService();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  let [missionList, setMissionList] = useState<MissionResult[]>([]);
  useEffect(() => {
    missionService.getMissions(isLogin, 1, 10).subscribe((data) => {
      if (data.data && data.data.results && data.data.results.length > 0) {
        setMissionList(data.data.results);
      }
    });
  }, []);

  return (
    <WidgetContainerComponent className={'totalCenter'}>
      <ColumnComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      </ColumnComponent>
      <RowComponent className={'flex-nowrap'}>
        {missionList.length > 0 ? (
          <Swiper
            slidesPerView={'auto'}
            freeMode={true}
            modules={[FreeMode]}
            style={{ width: '100%', paddingRight: '.6rem', paddingLeft: '.6rem' }}
            className="mySwiper"
          >
            {missionList.map((item, index) => (
              <SwiperSlide
                className={'!w-10/12 md:!w-1/3 lg:!w-1/4'}
                style={{ paddingLeft: '.2rem', paddingRight: '.4rem', paddingBottom: '.6rem' }}
                key={index}
                virtualIndex={index}
              >
                <MissionItemComponent mission={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : <RowComponent className="flex-nowrap overflow-scroll hideScrollBar">
            {[...Array(5)].map((i, index) => (
                <div key={index} className="w-auto p-2 mb-20">
                    <Skeleton className="card card-box rounded-1 videoBannerIconImage" variant="rectangular" />
                </div>
            ))}
        </RowComponent>}
      </RowComponent>
    </WidgetContainerComponent>
  );
};
