import React, { FC } from 'react';
import { MenuBuilderWidgetModel } from '../../../../Main/main.model';
import ToolsWidgetComponent from './toolsWidget.component';
import HorizontalWidgetComponent from './horizontalWidget.component';
import AdsWidgetComponent from './ads/adsWidget.component';
import { FavoriteWidgetComponent } from './favorite/favoriteWidget.component';
import {
  WIDGET_ADVERTISEMENT,
  WIDGET_BANNER_SLIDER,
  WIDGET_BANNER_SWIPER,
  WIDGET_CALENDAR,
  WIDGET_CAMPAIGNS,
  WIDGET_DISCOUNTS,
  WIDGET_EXCHANGE_RATE,
  WIDGET_FAVORITE,
  WIDGET_HORIZONTAL,
  WIDGET_HORIZONTAL_CATEGORY,
  WIDGET_HORIZONTAL_RECTANGULAR,
  WIDGET_HORIZONTAL_SQUARE,
  WIDGET_MEDIA_SWIPER,
  WIDGET_MISSIONS,
  WIDGET_SHORT_VIDEO,
  WIDGET_SHORTCUT_SQUARE_SWIPER,
  WIDGET_SHORTCUT_SWIPER,
  WIDGET_TOOLS,
  WIDGET_VERTICAL,
  WIDGET_WALLET
} from '../CONSTANTS';
import HorizontalSquareWidgetComponent from './horizontalSquareWidget.component';
import HorizontalCategoryWidgetComponent from './horizontalCategoryWidget.component';
import BannerSwiperWidgetComponent from './bannerSwiper.component';
import BannerSliderWidgetComponent from './bannerSlider.component';
import MediaSwiperWidgetComponent from './mediaSwiper.component';
import VerticalWidgetComponent from './verticalWidget.component';
import ShortcutSquareSwiperComponent from './shortcutSquareSwiper.component';
import { ColumnComponent } from '../../../../../core/components/base/column.component';
import { ShortcutSwiperComponent } from './shortcutSwiper.component';
import { CampaignWidgetComponent } from './campaignWidget.component';
import { DiscountWidgetComponent } from './discountWidget.component';
import { MissionWidgetComponent } from './missionWidget.component';
import { HorizontalRectangularSwiperComponent } from './horizontalRectangularSwiper.component';
import ShortVideoWidgetComponent from './shortVideoSwiper.component';
import WalletWidgetComponent from './walletWidget.component';
import { ExchangeRateWidgetComponent } from './exchangeRateWidget.component';
import { CalendarWidgetComponent } from './calendarWidget.component';

interface WidgetProps {
  widget: MenuBuilderWidgetModel;
}

export const WidgetComponent: FC<WidgetProps> = (props) => {
  function renderWidget() {
    switch (props.widget?.blueprint_unique_name) {
      case WIDGET_TOOLS:
        return <ToolsWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_ADVERTISEMENT:
        return <AdsWidgetComponent widget={props.widget} hasTitle={false} />;
      case WIDGET_HORIZONTAL:
        return <HorizontalWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_VERTICAL:
        return <VerticalWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_HORIZONTAL_SQUARE:
        return <HorizontalSquareWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_HORIZONTAL_CATEGORY:
        return <HorizontalCategoryWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_BANNER_SWIPER:
        return <BannerSwiperWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_BANNER_SLIDER:
        return <BannerSliderWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_MEDIA_SWIPER:
        return <MediaSwiperWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_SHORTCUT_SQUARE_SWIPER:
        return <ShortcutSquareSwiperComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_SHORTCUT_SWIPER:
        return <ShortcutSwiperComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_FAVORITE:
        return <FavoriteWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_CAMPAIGNS:
        return <CampaignWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_MISSIONS:
        return <MissionWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_DISCOUNTS:
        return <DiscountWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_HORIZONTAL_RECTANGULAR:
        return <HorizontalRectangularSwiperComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_SHORT_VIDEO:
        return <ShortVideoWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_WALLET:
        return <WalletWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_EXCHANGE_RATE:
        return <ExchangeRateWidgetComponent widget={props.widget} hasTitle={true} />;
      case WIDGET_CALENDAR:
        return <CalendarWidgetComponent widget={props.widget} hasTitle={true} />;
      default:
        return <ToolsWidgetComponent widget={props.widget} hasTitle={true} />;
    }
  }

  return <ColumnComponent className={'totalCenter'}>{props.widget ? renderWidget() : null}</ColumnComponent>;
};
