import {Observable} from 'rxjs';
import Api from '../../../../core/services/Api';
import {DATA_BASE_ADDRESS} from '../../drug_store/CONSTANTS';
import {CalendarResponseModel} from '../model/calendarEvents.model';

export class CalendarService {
  public getCalendarEvents(): Observable<CalendarResponseModel> {
    return Api.get<CalendarResponseModel>(DATA_BASE_ADDRESS + 'events.json');
  }
}
