import {Observable} from 'rxjs';
import Api from '../../core/services/Api';
import {MENU_BUILDER_SETTINGS_URL} from './CONSTANTS';
import {MenuBuilderMainModel} from './main.model';
import {getApiHeaderObject} from '../../core/services/config';
import {getAppPackage} from '../../core/services/localStorage.service';
import i18n from 'i18next';
import {AxiosResponse} from 'axios';

export class MainService {
  public getMenuBuilderPages(language?:string): Observable<MenuBuilderMainModel> {
    return Api.getWithHeader<MenuBuilderMainModel>(
      MENU_BUILDER_SETTINGS_URL,
      getApiHeaderObject(getAppPackage(), language??i18n.language)
    );
  }

  public getMenuBuilderPagesHead(): Observable<AxiosResponse> {
    return Api.head<AxiosResponse>(MENU_BUILDER_SETTINGS_URL);
  }
}
