import React, {FC, useEffect, useState} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {CAMPAIGN_IN_PROGRESS, CAMPAIGN_NOT_STARTED} from '../../../../club/campaign/CONSTANTS';
import {CampaignService} from '../../../../club/campaign/service/campaignService';

import {LOGIN_STATE_KEY} from '../../../../../core/services/CONSTANTS';
import {CampaignResults} from '../../../../club/campaign/model/campaign.model';
import {ActiveCampaignItemComponent} from '../../../../club/campaign/component/activeCampaignItem.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode} from 'swiper';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';
import {Skeleton} from "@mui/material";

export const CampaignWidgetComponent: FC<WidgetProps> = (props) => {
  const campaignService = new CampaignService();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [activeCampaignList, setActiveCampaignList] = useState<CampaignResults[]>([]);
  useEffect(() => {
    campaignService.getCampaigns(isLogin).subscribe((data) => {
      if (data.data && data.data.results && data.data.results.length > 0) {
        setActiveCampaignList(
          data.data.results.filter(
            (result) => result.status == CAMPAIGN_IN_PROGRESS || result.status == CAMPAIGN_NOT_STARTED
          )
        );
      }
    });
  }, []);

  return (
    <WidgetContainerComponent className={'totalCenter'}>
      <ColumnComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      </ColumnComponent>
      <RowComponent className={'flex-nowrap'}>
        {activeCampaignList.length > 0 ? (
          <Swiper
            slidesPerView={'auto'}
            freeMode={true}
            modules={[FreeMode]}
            style={{ width: '100%', paddingRight: '.6rem', paddingLeft: '.6rem' }}
            className="mySwiper"
          >
            {activeCampaignList.map((campaign, index) => (
              <SwiperSlide
                className={'!w-10/12 md:!w-1/3 lg:!w-1/4'}
                style={{ paddingLeft: '.2rem', paddingRight: '.4rem', paddingBottom: '.6rem' }}
                key={index}
                virtualIndex={index}
              >
                <ActiveCampaignItemComponent campaign={campaign} key={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : <RowComponent className="flex-nowrap overflow-scroll hideScrollBar">
            {[...Array(5)].map((i, index) => (
                <div key={index} className="w-auto p-2 mb-20">
                    <Skeleton className="card card-box rounded-1 videoBannerIconImage" variant="rectangular" />
                </div>
            ))}
        </RowComponent>}
      </RowComponent>
    </WidgetContainerComponent>
  );
};
