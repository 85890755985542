import React, {FC, useEffect, useRef} from 'react';
import {ContainerComponent} from '../../../../core/components/container.component';
import {MenuBuilderDetailModel} from '../../../Main/main.model';
import {WidgetComponent} from './widget/widget.component';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import {MainService} from '../../../Main/main.service';
import i18n from 'i18next';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {MenuDatabaseHelper} from './database/menu.database.helper';
import {default as staticPage} from './database/data/static.json';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';
import {useRouter} from 'next/router';

interface ItemPageProps {
  page?: string;
}

export const MenuBuilderComponent: FC<ItemPageProps> = (props) => {
  const [value, setValue] = React.useState('0');
  const [updateMenu, setUpdateMenu] = React.useState(false);
  let reserveLanguage = '';
  const router = useRouter();
  const { page } = router.query;
  const databaseHelper = new MenuDatabaseHelper();
  const mainService = new MainService();
  const [currentPage, setCurrentPage] = React.useState<MenuBuilderDetailModel | null>(null);

  function getMenuPageFromDatabase() {
    databaseHelper.getMenuPage(props.page ?? (page as string) ?? '').then((page) => {
      if (page && page.length > 0) {
        const mainPage: MenuBuilderDetailModel = {
          id: page[0].id,
          title: page[0].title,
          unique_name: page[0].unique_name,
          group: page[0].group,
          widgets: [],
          sub_pages: []
        };
        databaseHelper.getMenuItems(mainPage, page[0].widgetIds).then((page) => {
          if (page) {
            mainPage.widgets = page;
            setCurrentPage(mainPage);
          }
        });
      } else {
        getMenuBuilderData();
        // @ts-ignore
        let pageData: MenuBuilderDetailModel[] = staticPage as MenuBuilderDetailModel[];
        pageData.forEach((item) => {
          if (item.group === (props.page ?? page ?? '')) {
            setCurrentPage(item);
            return;
          }
        });
      }
    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    getMenuPageFromDatabase();
    reserveLanguage = i18n.language;
  }, [props.page, page, i18n.language, updateMenu]);
  let firstSwiper = useRef<SwiperClass>();

  function getMenuBuilderData() {
    mainService.getMenuBuilderPages().subscribe(
      (data) => {
        if (data && data.data) {
          new MenuDatabaseHelper().addMenuItems(data.data).then(() => {
            setUpdateMenu(true);
          });
        }
      },
      (error) => {
        console.log('error');
        console.log(error);
      }
    );
  }

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
    firstSwiper?.current?.slideTo(Number(newValue));
  };

  return (
    <ContainerComponent>
      <ColumnComponent className="fullScreenWithToolbar">
        {currentPage && (currentPage.widgets || currentPage.sub_pages) ? (
          currentPage.sub_pages.length > 0 ? (
            <TabContext value={value}>
              <TabList
                className="fixed toolsTabContainer"
                variant={'scrollable'}
                orientation={'horizontal'}
                onChange={handleChange}
              >
                {currentPage.sub_pages.map((item, index) => (
                  <Tab key={index} className="text-bold text-base font" label={item.title} value={`${index}`} />
                ))}
              </TabList>
              <div className="w-full tabMargin">
                <Swiper
                  onSlideChange={(swiper) => setValue(swiper.activeIndex.toString())}
                  onSwiper={(sw) => (firstSwiper.current = sw)}
                >
                  {currentPage.sub_pages.map((item, index) => (
                    <TabPanel key={index} className="p-0" value={`${index}`}>
                      <SwiperSlide key={index} virtualIndex={index}>
                        <MenuBuilderComponent page={item.group} />
                      </SwiperSlide>
                    </TabPanel>
                  ))}
                </Swiper>
              </div>
            </TabContext>
          ) : (
            <div className="flex flex-col w-full">
              {currentPage.widgets?.map((item, index) => (
                <WidgetComponent key={index} widget={item} />
              ))}
            </div>
          )
        ) : null}
      </ColumnComponent>
    </ContainerComponent>
  );
};
