import React, {FC, useEffect, useState} from 'react';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {useTranslation} from 'react-i18next';
import momentJalali from 'moment-jalaali';
import moment, {Moment} from 'moment-jalaali';
import momentHijri from 'moment-hijri';
import {toPersianNumber} from '../../../../../core/utils/number.utils';
import {PERSIAN, THEME_STATE_KEY} from '../../../../../core/services/CONSTANTS';
import {getAppLanguage} from '../../../../../core/services/localStorage.service';
import {useRouter} from 'next/router';
import {CALENDAR} from '../../../../../core/navigation/CONSTANTS';
import {CalendarService} from '../../../../tools/calendar/service/calendar.service';
import {CalendarEventsModel} from '../../../../tools/calendar/model/calendarEvents.model';
import momentCivil from 'moment';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';

export const CalendarWidgetComponent: FC<WidgetProps> = (props) => {
  const { t } = useTranslation();
  const [persianEvent, setPersianEvent] = useState<CalendarEventsModel[]>([]);
  const [hijriEvent, setHijriEvent] = useState<CalendarEventsModel[]>([]);
  const [civilEvent, setCivilEvent] = useState<CalendarEventsModel[]>([]);
  moment.loadPersian({ dialect: 'persian-modern' });
  let mj = momentJalali();
  mj.locale(getAppLanguage());
  let mh = momentHijri();
  // mh.add(-1,"iDate" as JUnitOfTime)
  let me = momentCivil();
  mh.locale(getAppLanguage() === PERSIAN ? 'ar-SA' : 'en');
  me.locale(getAppLanguage());
  const [persianMoment, setPersianMoment] = useState<Moment>(mj);
  const [hijriMoment, setHijriMoment] = useState<Moment>(mh);
  const [civilMoment, setCivilMoment] = useState<Moment>(me);
  const [isDark, setDark] = useLocalStorageState<boolean>(THEME_STATE_KEY, false);

  const router = useRouter();
  const calendarService = new CalendarService();

  useEffect(() => {
    calendarService.getCalendarEvents().subscribe((data) => {
      if (data) {
        let persianArray = data.PersianCalendar.filter(
          (item) => item.day == persianMoment.jDate() && item.month == persianMoment.jMonth() + 1
        );
        let hijriArray = data.HijriCalendar.filter(
          (item) => item.day == hijriMoment.iDate() && item.month == hijriMoment.iMonth() + 1
        );
        let civilArray = data.GregorianCalendar.filter(
          (item) => item.day == civilMoment.date() && item.month == civilMoment.month() + 1
        );
        setPersianEvent(persianArray);
        setHijriEvent(hijriArray);
        setCivilEvent(civilArray);
      }
    });
  }, []);

  function onClickAll() {
    router.push({ pathname: CALENDAR, search: `shTitle=${t('shName.calendar')}` });
  }

  return (
    <ColumnComponent className={'mt-2'}>
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <div
        className={'px-4 pt-2 pb-6 relative !w-full md:!w-1/2 lg:!w-1/3 flex flex-col pointer'}
        onClick={() => onClickAll()}
      >
        <ColumnComponent
          className={`rounded-lg py-4 px-4 mt-2`}
          style={{
            backgroundImage: isDark
              ? 'radial-gradient(farthest-corner at 10px 20px,#8ca5dd, #0517a3)'
              : 'radial-gradient(farthest-corner at 10px 20px,#f5e2a1, #f5b475)'
          }}
        >
          <div className={`text-bold text-lg ${isDark ? 'text-white' : ''}`}>
            {toPersianNumber(`${mj.format('dddd')} ${mj.jDate()} ${mj.format('jMMMM')} ${mj.jYear()}`)}
          </div>
          <div className={`mt-2 text-sm ${isDark ? 'text-white' : ''}`}>
            {toPersianNumber(`${mh.iDate()} ${mh.format('iMMMM')} ${mh.iYear()}`)} |{' '}
            {toPersianNumber(`${me.date()} ${me.format('MMMM')} ${me.year()}`)}
          </div>

          {persianEvent?.map((item, index) => (
            <div key={index} className={`text-sm mt-1 ${item.holiday ? 'mTextRed' : 'mText'}`}>
              {item.holiday ? t('engineering.holidayFor') : ''} {item.title}
            </div>
          ))}
          {hijriEvent?.map((item, index) => (
            <div key={index} className={`text-sm mt-1 ${item.holiday ? 'mTextRed' : 'mText'}`}>
              {item.holiday ? t('engineering.holidayFor') : ''} {item.title}
            </div>
          ))}
          {civilEvent?.map((item, index) => (
            <div key={index} className={`text-sm mt-1 ${item.holiday ? 'mTextRed' : 'mText'}`}>
              {item.holiday ? t('engineering.holidayFor') : ''} {item.title}
            </div>
          ))}
        </ColumnComponent>
        {isDark ? (
          <img alt={''} src={'/image/moon.png'} className={'w-16 absolute -top-4 left-8'} />
        ) : (
          <img alt={''} src={'/image/sun.png'} className={'w-16 absolute -top-4 left-8'} />
        )}
      </div>
    </ColumnComponent>
  );
};
