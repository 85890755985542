import React, {FC} from 'react';
import {MenuBuilderShortcutModel} from '../../../../Main/main.model';
import {LOGIN_STATE_KEY, PERSIAN} from '../../../../../core/services/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {onShortcutClick} from './service/shortcut.service';
import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';

import i18n from 'i18next';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {useRouter} from 'next/router';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import {Avatar} from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

interface ShortcutProps {
  shortcut: MenuBuilderShortcutModel;
  textColor?: string | null;
}

const RectangularShortcutComponent: FC<ShortcutProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();

  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

  function getShortcutTitle() {
    if (props.shortcut.title) {
      return i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en;
    } else {
      return t(`shName.${props.shortcut.unique_name}`);
    }
  }

  function handleShortcutClick() {
    if (props.shortcut?.is_enabled ?? true) {
      if ((props.shortcut.login_required ?? false) && !isLogin) {
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGIN_STATE
        });
      } else {
        let title = getShortcutTitle();
        onShortcutClick(props.shortcut, title, router, dispatch);
      }
    }
  }

  function getIconColor(icon_color: string) {
    let color = icon_color.replace('#', '');
    let a = color.substr(0, 2);
    color = color.substring(2, 8) + a;
    return '#' + color;
  }

  return (
    <div className="w-full">
      {props.shortcut ? (
        <ColumnComponent
          className="pointer"
          onClick={() => handleShortcutClick()}
          style={{ opacity: !(props.shortcut.is_enabled && !props.shortcut.is_enabled) ? '1' : '0.5' }}
        >
          <ColumnComponent className="totalCenter px-1">
            {props.shortcut.icon_url?.includes('http') ? (
              <Avatar
                variant="rounded"
                alt=""
                className="aspect-[2/3] mRounded-2 shortcutRectangularIconImage"
                style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
                src={props.shortcut.icon_url}
              >
                <ImageOutlinedIcon className="aspect-[1/1] w-full h-full p-2 opacity-50" />
              </Avatar>
            ) : (
              <Avatar
                variant="rounded"
                alt=""
                style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
                className="aspect-[2/3] mRounded-2 shortcutRectangularIconImage"
                src={`/image/png_ic_${props.shortcut.unique_name}.png`}
              >
                <ImageOutlinedIcon className="aspect-[1/1] w-full h-full p-2 opacity-50" />
              </Avatar>
            )}
          </ColumnComponent>
          {props.shortcut.title ? (
            <div
              className={`${props.textColor ? '' : 'mText'} text-center text-xs max-h-4 mt-2 w-full truncate`}
              style={{ color: props.textColor ?? 'black' }}
            >
              {i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en}
            </div>
          ) : (
            <div
              className={`${props.textColor ? '' : 'mText'} text-center text-xs max-h-4 mt-2 w-full truncate`}
              style={{ color: props.textColor ?? 'black' }}
            >
              {t(`shName.${props.shortcut.unique_name}`)}
            </div>
          )}
        </ColumnComponent>
      ) : null}
    </div>
  );
};

export default React.memo(RectangularShortcutComponent)
