import React, {FC} from 'react';
import {VideoListModel} from '../../../../tools/video/model/video.model';
import {toPersianNumber} from '../../../../../core/utils/number.utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {SHORT_VIDEO_PATH} from '../../../../../core/navigation/CONSTANTS';
import {useRouter} from "next/router";

interface ShortcutProps {
  video: VideoListModel;
  sourceUrl: string | null;
  index: number;
  widgetTitle: string | null;
}

const ShortVideoShortcutComponent: FC<ShortcutProps> = (props) => {
  const router = useRouter();

  function videoClick() {
    router.push({
      pathname: SHORT_VIDEO_PATH,
      search: `position=${props.index}&url=${encodeURIComponent(props.sourceUrl ?? '')}&shTitle=${props.widgetTitle}`
    });
  }

  return (
    <div className="w-full">
      {props.video ? (
        <ColumnComponent className="p-1 pointer" onClick={() => videoClick()}>
          <div className="aspect-[3/5] overflow-hidden relative mRounded-1 card card-box shortVideoIconImage">
            <img alt="" src={props.video.thumbnail_url} className="videoImageBlur" />
            <img alt="" src={props.video.thumbnail_url} className="videoImage" />
            <ColumnComponent className="text-wrap absolute z-10 bottom-0">
              <div className="fs-7 px-2 grow text-start text-wrap text-white" style={{ maxWidth: '10rem' }}>
                {toPersianNumber(props.video.title)}
              </div>
              <RowComponent className="flex flex-row w-auto justify-start px-1 items-center pb-2 campaignText">
                <VisibilityIcon className={'mt-1 text-white'} fontSize={'small'} />
                <div className="fs-7 text-end px-1 pt-1 text-white text-bold">
                  {toPersianNumber(props.video.views.toString())}
                </div>
              </RowComponent>
            </ColumnComponent>
          </div>
        </ColumnComponent>
      ) : null}
    </div>
  );
};

export default React.memo(ShortVideoShortcutComponent)
